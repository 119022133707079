import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import { Empresa } from 'entities/empresa';
import * as _ from 'lodash';
import { EyBasePage } from 'components/ey-base-page';
import { Api } from 'utils/api';
import { Documento } from 'entities/documento';
import { Trabajador } from 'entities/trabajador';
import { EyDocumentos, FilterType } from 'components/ey-documentos';
import { TrabajadorCdt } from 'entities/trabajadorCdt';
import { EmpresaCdT } from 'entities/centro';
import AuthService from 'authService';
import { EyTrabajadoresDesasignacion } from 'components/ey-trabajadores-desasignacion';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

@autoinject()
export class TrabajadorEdit extends EyBasePage {
  @observable trabajadorCdt: TrabajadorCdt;
  @observable empresa: Empresa;
  @observable empresaCdt: EmpresaCdT;
  @observable documentos: Documento[] = [];
  filterType: FilterType = FilterType.POR_SUBIR;

  docWidget: EyDocumentos;

  @observable empID: number = 0;
  constructor(private api: Api, public router: Router, private ea: EventAggregator, private auth: AuthService) {
    super(router);
  }

  activate(params) {

    console.log("TrabajadorEdit", params)
    this.empID = params.empID;
    this.getTrabajadorCdt(params.empID, params.empCdt, params.trabCdtID);
    if (params.filterType) {
      this.filterType = Number(params.filterType);
    }
  }

  attached() {
    this.docWidget.parent = this;
    this.docWidget.setFilter(this.filterType);
  }

  getTrabajadorCdt(empID: number, empCdt: number, trabCdtID: number) {

    if (!empID || !empCdt || !trabCdtID) {
      this.router.navigate('#/');
      return;
    }

    let params = {
      empresa: empID,
      empresa_cdt: empCdt
    }
    this.api.getOne(TrabajadorCdt, 'empresa_centro_trabajadores', trabCdtID, params)
      .then(item => {
        console.log("getTrabajadorCdt", item);
        if (item) {
          this.trabajadorCdt = item;
          this.empresa = this.api.empresaByID(empID);
          if (!this.empresa) {
            this.api.getEmpresas()
              .then(() => { this.empresa = this.api.empresaByID(empID); })
          }

          this.empresaCdt = this.api.empresaCdtByID(this.trabajadorCdt.empresa_cdt_id);
          if (!this.empresaCdt) {
            this.api.getEmpresasCdt(empID)
              .then(() => { this.empresaCdt = this.api.empresaCdtByID(this.trabajadorCdt.empresa_cdt_id); })
          }

          this.getDocumentos();
        }
      });
  }

  // updateTable() {
  //   if (this.docWidget) {
  //     this.docWidget.updateTable();
  //   }
  // }

  updateDocumentos() {
    this.getDocumentos();
  }

  getDocumentos() {
    let params = {
      empresa: this.empID,
      trabajador_cdt: this.trabajadorCdt.id,
    }

    this.api.getList(Documento, 'documentos', params)
      .then(items => {
        if (items) {
          this.documentos = items;
        }
        // this.updateTable();
      });
  }

  desasignarTrab(trabCdt: TrabajadorCdt) {
    this.api.showEditorDialog({ viewModel: EyTrabajadoresDesasignacion, model: { empresaCdt: this.empresaCdt, trab: trabCdt } })
      .then((response) => {
        if (response && !response.wasCancelled) {
          this.api.getTrabajadoresCdt(this.empresaCdt);
        }
      });
  }

  setFavorito(trabCdt: TrabajadorCdt) {

    trabCdt.favorito = (trabCdt.favorito) ? 0 : 1;
    this.api.saveOne(TrabajadorCdt, trabCdt, 'empresa_centro_trabajadores_actualiza', trabCdt.id)
      .then(response => {
        console.log("Response: ", response)

        if (response.error) {
          return;
        }
        this.getTrabajadorCdt(this.empID, trabCdt.empresa_cdt_id, trabCdt.id);
      });
  }
}
