import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import * as _ from 'lodash';
import { EyBasePage } from 'components/ey-base-page';
import { Api } from 'utils/api';
import { TrabajadorValidado } from 'entities/trabajador';
import moment from 'moment';
import * as $ from 'jquery';
import 'tempusdominus-bootstrap-4';
import 'tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.min.css';

let minDate = moment('1950-01-01');
let maxDate = moment('2050-01-01');
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

@autoinject()
export class TrabValidacion extends EyBasePage {

  // KIKO
  // cif: string = "B64249196";
  // dni: string = "25192742Z";
  // fecha: string = "15/12/1984";

  // Xavi
  // codigoControl: number = 25;
  // cif: string = "A48059208";
  // dni: string = "44003674M";
  // fecha: string = "05/04/1977";

  // Esther
  // cif: string = "B64249196";
  // dni: string = "44416044P";
  // fecha: string = "30/08/1974";

  // Dani
  // cif: string = "B64249196";
  // dni: string = "40333861A";
  // fecha: string = "7/01/1977";

  // Con foto
  // codigoControl: number = 5109;
  // cif: string = "B99999989";
  // dni: string = "90000002J";
  // fecha: string = "17/02/1995";

  cif: string = "";
  dni: string = "";
  fecha: string = "";
  codigoControl: number = 0;

  error: string = "";

  img: HTMLImageElement;
  @observable tv: TrabajadorValidado;

  constructor(private api: Api, public router: Router, private ea: EventAggregator) {
    super(router);
  }

  activate(params) {
  }

  attached() {
    $('#datetimepicker1').datetimepicker({
      format: 'L',
      locale: 'es',
      // defaultDate: moment(),
      minDate: minDate,
      maxDate: maxDate,
    });
  }

  validar() {
    this.error = "";

    if (this.codigoControl <= 0) {
      this.error = "Indique el Código de Empresa";
      return;
    }

    if (this.cif == "") {
      this.error = "Indique el CIF de la empresa";
      return;
    }

    if (this.dni == "") {
      this.error = "Indique el DNI/NIE del trabajador";
      return;
    }

    let date = $('#date').val()
    date = date.trim();
    let dt = moment(date);
    if (date == "" || dt < minDate || dt > maxDate) {
      this.error = "Fecha no válida";
      return;
    }

    this.cif = this.cif.trim().toUpperCase();
    this.dni = this.dni.trim().toUpperCase();

    console.log("validar", this.cif, this.dni, date)

    let params = {
      cif: this.cif,
      dni: this.dni,
      fecha: date,
      id: this.codigoControl,
    }
    this.api.getOne(TrabajadorValidado, 'trabajadores_validar', 0, params)
      .then(tv => {
        if (tv) {
          this.tv = tv;
        }
      });
  }

  reset() {
    if (this.img) {
      this.img.style.display = "";
    }
    this.tv = undefined;
  }

  imgNotFound() {
    console.log("image not found");
    // this.img.src = pathToPerson;
  }

  login(event: Event) {
    this.api.cancelEvent(event);
    this.router.navigateToRoute('login');
  }
}
