import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import { Empresa } from 'entities/empresa';
import * as _ from 'lodash';
import { EyBasePage } from 'components/ey-base-page';
import { Api } from 'utils/api';
import { Documento } from 'entities/documento';
import { Trabajador } from 'entities/trabajador';
import { EyDocumentos, FilterType } from 'components/ey-documentos';
import AuthService from 'authService';
import { EyTrabajadorForm } from 'components/ey-trabajador-form';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

@autoinject()
export class TrabajadorEdit extends EyBasePage {
  trabajador: Trabajador;
  @observable documentos: Documento[] = [];

  docWidget: EyDocumentos;
  filter: FilterType = FilterType.POR_SUBIR;

  constructor(private api: Api, public router: Router, private ea: EventAggregator, private auth: AuthService) {
    super(router);
  }

  activate(params) {

    console.log("TrabajadorEdit", params)
    this.getTrabajador(params.empID, params.trabID)
    if (params.filterType) {
      this.filter = Number(params.filterType);
    }
  }

  attached() {
    this.docWidget.parent = this;
    this.docWidget.setFilter(this.filter);
  }

  getTrabajador(empID: number, trabID: number) {

    if (!empID || !trabID) {
      this.router.navigate('#/');
      return;
    }

    let params = {
      empresa: empID,
    }
    this.api.getOne(Trabajador, 'trabajadores', trabID, params)
      .then(item => {
        console.log("getTrabajador", item);
        if (item) {
          this.trabajador = item;
          if (!this.trabajador.empresaCached) {
            this.api.getEmpresas()
              .then(() => { this.trabajador.empresaCached = this.api.empresaByID(this.trabajador.empresa); })
          }

          this.getDocumentos();
        }
      });
  }

  // updateTable() {
  //   if (this.docWidget) {
  //     this.docWidget.updateTable();
  //   }
  // }

  updateDocumentos() {
    this.getDocumentos();
  }

  getDocumentos() {
    let params = {
      empresa: this.trabajador.empresa,
      trabajador: this.trabajador.id,
    }

    this.api.getList(Documento, 'documentos', params)
      .then(items => {
        if (items) {
          this.documentos = items;
        }
        // this.updateTable();
      });
  }

  editTrab(trab: Trabajador) {
    this.api.showEditorDialog({ viewModel: EyTrabajadorForm, model: { trabajador: trab } })
      .then((response) => {
        if (response && !response.wasCancelled) {
          // this.api.showSnackbar('Información enviada correctamente', "");
          this.getTrabajador(trab.empresa, trab.id);
        }
      });
  }
}
