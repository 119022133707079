import { Api } from 'utils/api';
import { autoinject, observable } from 'aurelia-framework';
import { EventAggregator } from "aurelia-event-aggregator";
import AuthService from 'authService';
import { Application, AppSettings } from 'utils/app-settings';
import { Router } from 'aurelia-router';
import { App } from 'app';
import environment from 'environment';

@autoinject()
export class Login {
  username: string;
  password: string;
  rememberMe: boolean;
  error: string;

  // @observable showOpenID: boolean = false;

  constructor(public app: Application,
    public appSettings: AppSettings,
    private authService: AuthService,
    private ea: EventAggregator, private router: Router, private api: Api,
    private ourApp: App) {
    this.ea.subscribe("auth:login:error", (error) => {
      console.log("LOGIN ERROR!!");
      this.error = error
    });
  }

  activate(params) {
    this.username = '';
    this.password = '';
    this.error = '';

    console.log("activate", params,);

    if (params && params.s && params.u) {

      console.log("session cb", params.s, params.u);
      this.authService.loginWithSession(params.u, params.s);
    }
  }

  login() {

    if (this.username && this.password) {
      this.username = this.username.trim();
      this.password = this.password.trim();
      this.authService.login(this.username, this.password);
    } else {
      this.error = 'Introduzca usuario y contraseña.';
    }
  }

  validate(event: Event) {
    this.api.cancelEvent(event);
    this.router.navigateToRoute('validacion');
  }

  openid(event: Event) {
    this.api.cancelEvent(event);

    let openidurl = window.location.origin + "/openid/login";
    if (environment.debug) {
      openidurl = "http://localhost/openid/login";
    }

    window.location.replace(openidurl);
  }

  // get useOpenID(): boolean {
  //   return this.app.UseOpenID;
  // }
}
