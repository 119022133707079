import { autoinject, PLATFORM } from 'aurelia-framework';
import { EventAggregator } from "aurelia-event-aggregator";
import AuthService from './authService';
import { Application, AppSettings } from 'utils/app-settings';
import { Router, RouterConfiguration } from 'aurelia-router';

@autoinject()
export class Public {
  public router: Router;

  configureRouter(config: RouterConfiguration, router: Router) {
    config.map([
      { route: ['', 'login'], name: 'index', redirect: 'login' },
      { route: 'login', name: 'login', moduleId: PLATFORM.moduleName('pages/login/login') },
      { route: 'validacion', name: 'validacion', moduleId: PLATFORM.moduleName('pages/trabValidacion/validacion') },
    ]);
    this.router = router;

    //default route, to avoid the "route not found error"
    config.mapUnknownRoutes(instruction => {
      return PLATFORM.moduleName('pages/login/login');
    });
  }

  constructor(public app: Application,
    private authService: AuthService,
    private ea: EventAggregator) {
  }
}
