import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import { Empresa } from 'entities/empresa';
import * as _ from 'lodash';
import { EyBasePage } from 'components/ey-base-page';
import { Api } from 'utils/api';
import { Documento } from 'entities/documento';
import { Trabajador } from 'entities/trabajador';
import { EyDocumentos, FilterType } from 'components/ey-documentos';
import AuthService from 'authService';
import { EyTrabajadorForm } from 'components/ey-trabajador-form';
import { Maquina } from 'entities/maquinas';

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

@autoinject()
export class MaquinasEdit extends EyBasePage {
  maquina: Maquina;
  @observable documentos: Documento[] = [];

  docWidget: EyDocumentos;
  filter: FilterType = FilterType.POR_SUBIR;

  constructor(private api: Api, public router: Router, private ea: EventAggregator, private auth: AuthService) {
    super(router);
  }

  activate(params) {

    console.log("MaquinaEdit", params)
    this.getMaquina(params.empID, params.maqID)
    if (params.filterType) {
      this.filter = Number(params.filterType);
    }
  }

  attached() {
    this.docWidget.parent = this;
    this.docWidget.setFilter(this.filter);
  }

  getMaquina(empID: number, maqID: number) {

    if (!empID || !maqID) {
      this.router.navigate('#/');
      return;
    }

    let params = {
      empresa: empID,
    }
    this.api.getOne(Maquina, 'maquinas_empresas', maqID, params)
      .then(item => {
        console.log("getMaquina", item);
        if (item) {
          this.maquina = item;
          if (!this.maquina.empresaCached) {
            this.api.getEmpresas()
              .then(() => { this.maquina.empresaCached = this.api.empresaByID(this.maquina.empresa); })
          }

          this.getDocumentos();
        }
      });
  }

  // updateTable() {
  //   if (this.docWidget) {
  //     this.docWidget.updateTable();
  //   }
  // }

  updateDocumentos() {
    this.getDocumentos();
  }

  getDocumentos() {
    let params = {
      empresa: this.maquina.empresa,
      maquina: this.maquina.id,
    }

    this.api.getList(Documento, 'documentos', params)
      .then(items => {
        if (items) {
          this.documentos = items;
        }
        // this.updateTable();
      });
  }

  // editMaquina(maq: Maquina) {
  //   this.api.showEditorDialog({ viewModel: EyTrabajadorForm, model: { trabajador: trab } })
  //     .then((response) => {
  //       if (response && !response.wasCancelled) {
  //         // this.api.showSnackbar('Información enviada correctamente', "");
  //         this.getTrabajador(trab.empresa, trab.id);
  //       }
  //     });
  // }
}
